import { render, staticRenderFns } from "./collection_quality_details.vue?vue&type=template&id=4be1779e&scoped=true&"
import script from "./collection_quality_details.vue?vue&type=script&lang=js&"
export * from "./collection_quality_details.vue?vue&type=script&lang=js&"
import style0 from "./collection_quality_details.vue?vue&type=style&index=0&id=4be1779e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be1779e",
  null
  
)

export default component.exports